<template>
  <div>
    <div class="header-main">
      <h1 class="my-4 mx-10 page-title-main">
        {{ $t('corporateTicket.title') }}
      </h1>
    </div>
    <template>
      <div class="mx-10 mt-3">
        <v-card class="pa-5 mt-5 mb-5">
          <div style="width: 520px" class="d-flex justify-space-start">
            <v-text-field
              v-model="filter.ticketNo"
              @change="
                () => {
                  $refs.table.resetPageAndSearch();
                }
              "
              append-icon="mdi-magnify"
              :placeholder="$t('corporateTicket.placeHoderFormSearch')"
              hide-details
              class="t-search ml-5 text--small"
            ></v-text-field>
            <div class="d-flex justify-space-start mt-5">
              <span class="mx-5 mt-1">{{
                $t('corporateTicket.nameSearch')
              }}</span>
              <v-select
                :items="statusOptions"
                v-model="filter.isAvailable"
                @change="
                  () => {
                    $refs.table.resetPageAndSearch();
                  }
                "
                item-text="text"
                item-value="value"
                dense
                small
                color="var(--text__gray)"
                outlined
                hide-details
                class="v-select-custom ml-1"
              ></v-select>
            </div>
          </div>
          <Table
            :attr="{
              dense: true,
              'no-data-text': $t('rules.noData'),
              'item-key': 'id',
              class: 'pa-5 table-cus',
            }"
            ref="table"
            :itemsPerPage="10"
            :itemsPerPageOptions="[10, 50, 100, 200, 500]"
            :headers="header"
            :total="getTotalBookingTicketTypeList"
            :items="bookingTicketTypeList"
            :funReset="loadData"
            :filter="filter"
            :sortField="listSortField"
          >
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                :disabled="!checkPerUser"
                @click="
                  fetchDetailData(item.id),
                    (currentTitleDialog =
                      (item.intendedClient && item.intendedClient.name) || '')
                "
                icon
              >
                <v-icon x-large> mdi-pencil-circle</v-icon>
              </v-btn>
            </template>

            <!--  -->
            <template v-slot:[`item.createdAt`]="{ item }">
              {{
                item.createdAt
                  .substr(0, 10)
                  .split('T')
                  .join(' ')
                  .split('-')
                  .join('/')
              }}
            </template>
            <template v-slot:[`item.availableToDate`]="{ item }">
              {{
                item.availableToDate
                  .substr(0, 10)
                  .split('-')
                  .join('/')
              }}
            </template>
            <template v-slot:[`item.intendedClientName`]="{ item }">
              {{ item.intendedClient && item.intendedClient.name }}
            </template>
            <template v-slot:[`item.issuedCount`]="{ item }">
              {{ item.computed && item.computed.issuedCount }}
            </template>
            <template v-slot:[`item.roomTypeNames`]="{ item }">
              {{ getRoomTypeNameList(item.roomTypes) }}
            </template>
            <template v-slot:[`item.cancelDeadline`]="{ item }">
              失効期限{{ item.cancelDeadline }}日前
            </template>
            <template v-slot:[`item.bookingFromDate`]="{ item }">
              {{
                item.bookingFromDate
                  .substr(0, 10)
                  .split('-')
                  .join('/')
              }}
            </template>
            <template v-slot:[`item.bookingFromMonths`]="{ item }">
              宿泊日から{{ item.bookingFromMonths }}か月前
            </template>
            <template v-slot:[`item.bookingFromDays`]="{ item }">
              宿泊日から{{ item.bookingFromDays }}日前
            </template>
            <template v-slot:[`item.usedCount`]="{ item }">
              {{ item.computed && item.computed.usedCount }}
            </template>
            <template v-slot:[`item.availableCount`]="{ item }">
              {{ item.computed && item.computed.availableCount }}
            </template>
          </Table>
        </v-card>
      </div>
    </template>
    <corporate-ticket-detail
      :title="currentTitleDialog"
      :visible="visible === 1"
      @close="visible = -1"
      @changeStatus="fetchDetailData(currentId)"
    ></corporate-ticket-detail>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CorporateTicketDetail from './CorporateTicketDetail.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  data() {
    return {
      listSortField: [
        {
          colName: 'createdAt', // === value in headers
          name: 'createdAt',
          fieldName: 'ticketType',
        },
      ],
      filter: {
        ticketNo: null,
        isAvailable: true,
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      currentTitleDialog: null,
      visible: -1,
      currentId: null,
      statusOptions: [
        {
          text: '全て',
          value: null,
        },
        {
          text: '期限内のみ表示',
          value: true,
        },
        {
          text: '期限外のみ表示',
          value: false,
        },
      ],

      header: [
        {
          text: '',
          value: 'edit',
          sortable: false,
        },
        {
          text: '発行日',
          value: 'createdAt',
          sortable: true,
        },
        {
          text: '利用期限',
          value: 'availableToDate',
          sortable: false,
        },
        {
          text: '法人顧客名',
          value: 'intendedClientName',
          sortable: false,
        },
        {
          text: 'チケットNo.',
          value: 'id',
          sortable: false,
        },
        {
          text: '発行枚数',
          value: 'issuedCount',
          sortable: false,
        },
        {
          text: '名称',
          value: 'name',
          sortable: false,
        },
        {
          text: '施設',
          value: 'roomTypeNames',
          sortable: false,
        },
        {
          text: '失効期限',
          value: 'cancelDeadline',
          sortable: false,
        },
        {
          text: '予約開始日',
          value: 'bookingFromDate',
          sortable: false,
        },
        {
          text: '予約可能期間（月）',
          value: 'bookingFromMonths',
          sortable: false,
        },
        {
          text: '予約可能期間（日）',
          value: 'bookingFromDays',
          sortable: false,
        },
        {
          text: '利用枚数',
          value: 'usedCount',
          sortable: false,
        },
        {
          text: '利用可能',
          value: 'availableCount',
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$refs.table.reset();
  },
  computed: {
    ...mapGetters(['bookingTicketTypeList', 'getTotalBookingTicketTypeList']),
  },
  methods: {
    ...mapActions(['fetchDataBookingTicketTypeList', 'fetchBookingTicketType']),
    ...mapMutations([
      'setBookingTicketTypeListTake',
      'setBookingTicketTypeListSkip',
      'setBookingTicketTypeListSortDirection',
    ]),
    handleSort() {
      if (this.options.sortDesc[0]) {
        this.setBookingTicketTypeListSortDirection('DESC');
      } else {
        this.setBookingTicketTypeListSortDirection('ASC');
      }
    },
    getRoomTypeNameList(arr) {
      const nameList = [];
      arr?.length > 0 &&
        arr.map(item => {
          if (item.roomType.facility) {
            nameList.push(item.roomType.facility.name);
          }
        });
      return nameList.join(', ');
    },
    async fetchDetailData(id) {
      await this.fetchBookingTicketType(id);
      this.currentId = id;
      this.visible = 1;
    },
    async loadData(variables) {
      if (variables) {
        this.setBookingTicketTypeListTake(variables.pagination.take);
        this.setBookingTicketTypeListSkip(variables.pagination.skip);
        await this.fetchDataBookingTicketTypeList({
          ticketNo: variables.filter.ticketNo,
          isAvailable: variables.filter.isAvailable,
          orderBy: variables.orderBy,
        });
      }
    },
  },
  components: {
    CorporateTicketDetail,
    Table,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-select-custom {
    min-width: 130px !important;
  }
}
.information-request {
  max-width: 1258px !important;
  margin-top: 15px;
  margin-left: 42px;
  .card-title {
    label {
      font-size: 13px;
    }
  }
  .custom-table {
    .status {
      height: 27px;
      width: 63px;
      border-radius: 6px !important;
    }
    .status_btn {
      height: 28px !important;
      width: 86px;
      font-size: 15px;
      color: white;
      box-shadow: none;
    }
  }
}
.pencil {
  background: var(--ffffff) 0% 0% no-repeat padding-box !important;
  background: #3083dc 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1;
  height: 30px;
  width: 30px;
}
.contact {
  color: #3083dc !important;
}
.icon-btn {
  border-radius: 50%;
  font-size: 23px !important;
  color: #fff !important;
}

.btn-left {
  border-right: solid 1px rgb(190, 179, 179);
  margin: auto 0;
  border-radius: 0px;
  background: #f5f5f5 !important;
}
.btn-right {
  margin: auto 0;
  border-radius: 0px;
  background: #f5f5f5 !important;
}
.v-data-table::v-deep {
  th {
    color: #000 !important;
  }
  .v-data-footer,
  .v-select__selection {
    font-size: 14px !important;
  }
  &.table-cus {
    table tr th,
    table tr td {
      min-width: 100px;
    }
    table tr th {
      white-space: nowrap;
    }
  }
}
</style>
