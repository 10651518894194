var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-main"},[_c('h1',{staticClass:"my-4 mx-10 page-title-main"},[_vm._v(" "+_vm._s(_vm.$t('corporateTicket.title'))+" ")])]),[_c('div',{staticClass:"mx-10 mt-3"},[_c('v-card',{staticClass:"pa-5 mt-5 mb-5"},[_c('div',{staticClass:"d-flex justify-space-start",staticStyle:{"width":"520px"}},[_c('v-text-field',{staticClass:"t-search ml-5 text--small",attrs:{"append-icon":"mdi-magnify","placeholder":_vm.$t('corporateTicket.placeHoderFormSearch'),"hide-details":""},on:{"change":function () {
                _vm.$refs.table.resetPageAndSearch();
              }},model:{value:(_vm.filter.ticketNo),callback:function ($$v) {_vm.$set(_vm.filter, "ticketNo", $$v)},expression:"filter.ticketNo"}}),_c('div',{staticClass:"d-flex justify-space-start mt-5"},[_c('span',{staticClass:"mx-5 mt-1"},[_vm._v(_vm._s(_vm.$t('corporateTicket.nameSearch')))]),_c('v-select',{staticClass:"v-select-custom ml-1",attrs:{"items":_vm.statusOptions,"item-text":"text","item-value":"value","dense":"","small":"","color":"var(--text__gray)","outlined":"","hide-details":""},on:{"change":function () {
                  _vm.$refs.table.resetPageAndSearch();
                }},model:{value:(_vm.filter.isAvailable),callback:function ($$v) {_vm.$set(_vm.filter, "isAvailable", $$v)},expression:"filter.isAvailable"}})],1)],1),_c('Table',{ref:"table",attrs:{"attr":{
            dense: true,
            'no-data-text': _vm.$t('rules.noData'),
            'item-key': 'id',
            class: 'pa-5 table-cus',
          },"itemsPerPage":10,"itemsPerPageOptions":[10, 50, 100, 200, 500],"headers":_vm.header,"total":_vm.getTotalBookingTicketTypeList,"items":_vm.bookingTicketTypeList,"funReset":_vm.loadData,"filter":_vm.filter,"sortField":_vm.listSortField},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.checkPerUser,"icon":""},on:{"click":function($event){_vm.fetchDetailData(item.id),
                  (_vm.currentTitleDialog =
                    (item.intendedClient && item.intendedClient.name) || '')}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-pencil-circle")])],1)]}},{key:"item.createdAt",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt .substr(0, 10) .split('T') .join(' ') .split('-') .join('/'))+" ")]}},{key:"item.availableToDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.availableToDate .substr(0, 10) .split('-') .join('/'))+" ")]}},{key:"item.intendedClientName",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.intendedClient && item.intendedClient.name)+" ")]}},{key:"item.issuedCount",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.computed && item.computed.issuedCount)+" ")]}},{key:"item.roomTypeNames",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoomTypeNameList(item.roomTypes))+" ")]}},{key:"item.cancelDeadline",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" 失効期限"+_vm._s(item.cancelDeadline)+"日前 ")]}},{key:"item.bookingFromDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.bookingFromDate .substr(0, 10) .split('-') .join('/'))+" ")]}},{key:"item.bookingFromMonths",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" 宿泊日から"+_vm._s(item.bookingFromMonths)+"か月前 ")]}},{key:"item.bookingFromDays",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" 宿泊日から"+_vm._s(item.bookingFromDays)+"日前 ")]}},{key:"item.usedCount",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.computed && item.computed.usedCount)+" ")]}},{key:"item.availableCount",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.computed && item.computed.availableCount)+" ")]}}],null,true)})],1)],1)],_c('corporate-ticket-detail',{attrs:{"title":_vm.currentTitleDialog,"visible":_vm.visible === 1},on:{"close":function($event){_vm.visible = -1},"changeStatus":function($event){return _vm.fetchDetailData(_vm.currentId)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }